<!--
 * @Description: 车行道停车场管理 路段管理 路段配置 区域管理 onroadRoadParkSpaceArea
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-03-16 09:33:36
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainbody'>
    <el-row class="dt-back">
      <i class="el-icon-back"
         @click="handleBack"></i>
      <span>路段信息管理</span>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <el-col :span="12">区域管理</el-col>
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="insertUser">新 增</el-button>
          <el-button type="danger"
                     style="margin-left:17px;"
                     @click="handleDelList">批量删除</el-button>
        </el-col>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList"
                  :tableFun="tableFun">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="泊位配置">
              <template slot-scope="scope">
                <el-button @click="handleClickLineItem(scope.row)"
                           type="text"
                           size="medium">泊位管理</el-button>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button @click="handleOrderUpdate(scope.$index, scope.row)"
                             type="text"
                             size="medium">修改</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             @click="handleOrderDelete(scope.$index, scope.row)">删除</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增、修改 -->
    <el-dialog :title="dialogTitle"
               append-to-body
               :visible.sync="insterDialog"
               @close="popClose">
      <el-form :model="instreForm"
               :label-position="labelPosition"
               :rules="rules"
               ref="instreForm"
               size="small"
               label-width="110px">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="区域名称"
                          prop="parkLayerName">
              <el-input v-model="instreForm.parkLayerName"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="区域车位数"
                          prop="parkSpaceNum">
              <el-input v-model="instreForm.parkSpaceNum"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="区域描述"
                          prop="remark">
              <el-input v-model="instreForm.remark"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-row class="dt-button-box">
            <el-button type="success"
                       size="medium"
                       @click="handleSure('instreForm')">确 定</el-button>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  data () {
    //这里存放数据
    return {
      tableFun: { 'selection-change': this.handleChange, },
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: { pageNum: 1, pageSize: 15, },
      tableList: { list: [] },
      pageNum: 1, // 分页
      pageSize: 15,
      searchForm: {
        parkId: '',// 路段id
      },
      tableColumnList: [
        {
          prop: 'parkLayerName',
          label: '区域名称',
        },
        {
          prop: 'parkSpaceNum',
          label: '区域车位数',
        },
        {
          prop: 'remark',
          label: '区域描述',
        },
      ],
      MultiSelect: [], // 多选列表
      labelPosition: 'left', //对齐方向
      dialogVisible: false,
      instreForm: {},// 新增表
      insterDialog: false,// 新增修改弹窗开关
      dialogTitle: '',//弹出框标题
      rules: {
        parkLayerName: [{ required: true, message: '请输入', trigger: 'blur' }],
        parkSpaceNum: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.searchForm.parkId = this.$route.query.parkId
    this.queryTableList()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 点击泊位管理
    handleClickLineItem (row) {
      this.$router.push({ path: '/onroadRoadParkSpaceNext', query: { parkLayerId: row.parkLayerId, parkId: this.searchForm.parkId } })
    },
    // 获取区域信息
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$yardManagementApi.ParkLayerControllerWithPage(this.searchForm).then(response => {
        this.tableList.list = response.resultEntity.list
        this.paginationConfig.total = response.resultEntity.total
      })
    },
    //分页器
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 新增
    insertUser () {
      this.dialogTitle = '新增'
      this.insterDialog = true
    },
    //修改
    handleOrderUpdate (index, row) {
      this.instreForm = this.$deepClone(row)
      this.dialogTitle = '修改'
      this.insterDialog = true
    },
    // 确定
    handleSure (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogTitle === '新增') {
            this.$yardManagementApi.insertParkLayerController({ ...this.searchForm, ...this.instreForm }).then(() => {
              this.$message({ showClose: true, message: '新增成功', type: 'success' })
              this.queryTableList()
              this.insterDialog = false
            })
          } else if (this.dialogTitle === '修改') {
            this.$yardManagementApi.updateParkLayerController({ ...this.searchForm, ...this.instreForm }).then(() => {
              this.$message({ showClose: true, message: '修改成功', type: 'success' });
              this.queryTableList()
              this.insterDialog = false
            })
          }
        } else {
          return false;
        }
      });
    },
    // 多选
    handleChange (val) {
      this.MultiSelect = []
      for (let index in val) {
        let info = { parkId: this.searchForm.parkId, parkLayerId: val[index].parkLayerId }
        this.MultiSelect.push(info)
      }
    },
    // 批量删除按钮
    handleDelList () {
      if (this.MultiSelect.length > 0) {
        this.delete()
      } else {
        this.$message({ showClose: true, message: '请选择要删除的数据' });
      }
    },
    // 删除操作
    delete () {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$yardManagementApi.deleteParkLayerController(this.MultiSelect).then(() => {
          this.$message({ showClose: true, message: '删除成功', type: 'success' });
          this.queryTableList()
        })
      })
    },
    //删除
    handleOrderDelete (index, row) {
      this.MultiSelect = []
      this.MultiSelect.push({ parkId: this.searchForm.parkId, parkLayerId: row.parkLayerId })
      this.delete()
    },
    popClose () {
      this.instreForm = {}
      this.$refs.instreForm.resetFields();
    },
    // 返回箭头
    handleBack () {
      window.history.back(-1);
    },
  },
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 130px;
.mainbody {
  height: 100%;
  .dt-back {
    color: #bae8ff;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topButton {
      display: flex;
      padding-left: 91px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      line-height: 32px;
      margin-top: 15px;
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
</style>
